//根据不同的环境更改不同的baseUrl
let baseUrl = '';

if (process.env.NODE_ENV == 'development') {
	//baseUrl = 'http://172.16.10.57:7011/greatWall';
	baseUrl = 'http://api-wall.just-print.cn:7011/greatWall';
} else if (process.env.NODE_ENV == 'production') {
	baseUrl = 'http://api-wall.just-print.cn:7011/greatWall';
	// baseUrl = 'http://106.55.52.106:7011/greatWall';
}

export {
	baseUrl
}
