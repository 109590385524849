<template>
	<router-view :key="$route.fullPath" />
</template>

<script>
	import {
		getCurrentInstance
	} from 'vue'
	export default {
		name: "App",
		data() {
			return {

			};
		},
		
		mounted() {
			// 是否置灰，默认不置灰
			this.queryCommonParam();
		},

		methods: {
			async queryCommonParam() {
				const internalInstance = getCurrentInstance();
				let params = {
					id: 1
				};
				internalInstance.appContext.config.globalProperties
					.$get("/website/v1/web/user/detail", params)
					.then((res) => {
						if (res.success) {
							if (res.data.webIsGray == 1) {
								let html_box_gray = document.getElementById("html_box_gray");
								html_box_gray.style.filter = 'grayscale(100%)';
							} else {
								let html_box_gray = document.getElementById("html_box_gray");
								html_box_gray.style.filter = 'grayscale(0)';
							}
						}
					});
			}
		}
	}
</script>


<style>
	@import "./assets/css/base.scss";
</style>
