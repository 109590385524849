<template>
	<div id="wrapper" class="flex-col">
		<Header />
		<div class="search-box flex-row at-c">
			<input class="search-input" type="text" placeholder="请输入搜索内容" v-model.trim="searchTxt"
				@keyup.enter="toSearch">
			<span class="search-btn" @click="toSearch">搜索</span>
		</div>
		<div class="article_main flex-col">
			<Swiper :list="swiperList" />
			<div class="article_part flex-col">
				<div class="article_tab flex-row" v-show="tabOne!=0">
					<span class="article_tab_li" :class="{active:tabOne == 1}" @click.stop="tabOne=1">特别关注</span>
					<span class="article_tab_li" :class="{active:tabOne == 2}" @click.stop="tabOne=2">新闻资讯</span>
					<span class="article_tab_li" :class="{active:tabOne == 3}" @click.stop="tabOne=3">党的建设</span>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabOne==0"
						@click.stop="toArticleList(13)">
						<span class="tl">特别关注</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabOne==0 || tabOne==1">
						<div class="article_cell flex-row" v-for="item in tabOneList_1" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabOne==0"
						@click.stop="toArticleList(14)">
						<span class="tl">新闻资讯</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabOne==0 || tabOne==2">
						<div class="article_cell flex-row" v-for="item in tabOneList_2" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col" v-show="tabOne==0 || tabOne==3">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabOne==0"
						@click.stop="toArticleList(15)">
						<span class="tl">党的建设</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col">
						<div class="article_cell flex-row" v-for="item in tabOneList_3" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="article_part flex-col">
				<div class="article_tab flex-row" v-show="tabTwo!=0">
					<span class="article_tab_li" :class="{active:tabTwo == 1}" @click.stop="tabTwo=1">活动策划</span>
					<span class="article_tab_li" :class="{active:tabTwo == 2}" @click.stop="tabTwo=2">地方动态</span>
					<span class="article_tab_li" :class="{active:tabTwo == 3}" @click.stop="tabTwo=3">数字长城</span>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabTwo==0"
						@click.stop="toArticleList(16)">
						<span class="tl">活动策划</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabTwo==0 || tabTwo==1">
						<div class="article_cell flex-row" v-for="item in tabTwoList_1" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabTwo==0"
						@click.stop="toArticleList(17)">
						<span class="tl">地方动态</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabTwo==0 || tabTwo==2">
						<div class="article_cell flex-row" v-for="item in tabTwoList_2" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col" v-show="tabTwo==0 || tabTwo==3">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabTwo==0"
						@click.stop="toArticleList(10)">
						<span class="tl">数字长城</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col">
						<div class="article_cell flex-row" v-for="item in tabTwoList_3" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="article_part flex-col">
				<div class="article_tab flex-row" v-show="tabThree!=0">
					<span class="article_tab_li" :class="{active:tabThree == 1}" @click.stop="tabThree=1">长城学会</span>
					<span class="article_tab_li" :class="{active:tabThree == 2}" @click.stop="tabThree=2">长城资讯</span>
					<span class="article_tab_li" :class="{active:tabThree == 3}" @click.stop="tabThree=3">长城文博</span>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabThree==0"
						@click.stop="toArticleList(1)">
						<span class="tl">长城学会</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabThree==0 || tabThree==1">
						<div class="article_cell flex-row" v-for="item in tabThreeList_1" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabThree==0"
						@click.stop="toArticleList(2)">
						<span class="tl">长城资讯</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabThree==0 || tabThree==2">
						<div class="article_cell flex-row" v-for="item in tabThreeList_2" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col" v-show="tabThree==0 || tabThree==3">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabThree==0"
						@click.stop="toArticleList(3)">
						<span class="tl">长城文博</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col">
						<div class="article_cell flex-row" v-for="item in tabThreeList_3" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="article_part flex-col">
				<div class="article_tab flex-row" v-show="tabThour!=0">
					<span class="article_tab_li" :class="{active:tabThour == 1}" @click.stop="tabThour=1">长城人物</span>
					<span class="article_tab_li" :class="{active:tabThour == 2}" @click.stop="tabThour=2">长城保护 </span>
					<span class="article_tab_li" :class="{active:tabThour == 3}" @click.stop="tabThour=3">长城公园</span>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabThour==0"
						@click.stop="toArticleList(4)">
						<span class="tl">长城人物</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabThour==0 || tabThour==1">
						<div class="article_cell flex-row" v-for="item in tabThourList_1" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabThour==0"
						@click.stop="toArticleList(5)">
						<span class="tl">长城保护</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabThour==0 || tabThour==2">
						<div class="article_cell flex-row" v-for="item in tabThourList_2" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col" v-show="tabThour==0 || tabThour==3">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabThour==0"
						@click.stop="toArticleList(6)">
						<span class="tl">长城公园</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col">
						<div class="article_cell flex-row" v-for="item in tabThourList_3" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="article_part flex-col">
				<div class="article_tab flex-row" v-show="tabFive!=0">
					<span class="article_tab_li" :class="{active:tabFive == 1}" @click.stop="tabFive=1">长城论坛</span>
					<span class="article_tab_li" :class="{active:tabFive == 2}" @click.stop="tabFive=2">长城旅游</span>
					<span class="article_tab_li" :class="{active:tabFive == 3}" @click.stop="tabFive=3">长城艺苑</span>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabFive==0"
						@click.stop="toArticleList(7)">
						<span class="tl">长城论坛</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabFive==0 || tabFive==1">
						<div class="article_cell flex-row" v-for="item in tabFiveList_1" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabFive==0"
						@click.stop="toArticleList(8)">
						<span class="tl">长城旅游</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col" v-show="tabFive==0 || tabFive==2">
						<div class="article_cell flex-row" v-for="item in tabFiveList_2" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
				<div class="article_items flex-col" v-show="tabFive==0 || tabFive==3">
					<div class="article_items_tl flex-row jc-sb at-c" v-show="tabFive==0"
						@click.stop="toArticleList(9)">
						<span class="tl">长城艺苑</span>
						<span class="more">更多</span>
					</div>
					<div class="article_items_its flex-col">
						<div class="article_cell flex-row" v-for="item in tabFiveList_3" :key="item.id"
							@click.stop="toArticleDetail(item.id)">
							<span class="article_cell_tl">{{item.articleTitle}}</span>
							<span class="article_cell_date">{{item.addTime.substring(0,10)}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="link-box flex-row at-c fw-w">
			<div class="tl">中国政府网</div>
			<select name="国家文物局" v-model="selectVal1" @change="changeSelectLink($event, 1)">
				<option disabled>国家文物局</option>
				<option value="http://jslcc.casboc.com/" title="金山岭长城">金山岭长城</option>
				<option value="http://www.badaling.cn/" title="八达岭长城">八达岭长城</option>
				<option value="http://www.shgjq.com/" title="山海关">山海关</option>
				<option value="http://www.yanmenguan.cn/lianxiwomen.html" title="雁门关">雁门关</option>
			</select>
			<select name="二级机构" v-model="selectVal2" @change="changeSelectLink($event, 2)">
				<option disabled>二级机构</option>
			</select>
			<select name="相关单位" v-model="selectVal3" @change="changeSelectLink($event, 3)">
				<option disabled>相关单位</option>
				<option value="http://www.ncha.gov.cn/" title="相关单位">国家文物局</option>
				<option value="http://jslcc.casboc.com/" title="金山岭长城">金山岭长城</option>
				<option value="http://www.badaling.cn/" title="八达岭长城">八达岭长城</option>
				<option value="http://www.shgjq.com/" title="山海关">山海关</option>
				<option value="http://www.yanmenguan.cn/lianxiwomen.html" title="雁门关">雁门关</option>
			</select>
			<select name="8+1联盟" v-model="selectVal4" @change="changeSelectLink($event, 4)">
				<option disabled>8+1联盟</option>
				<option value="http://www.shihan.edu.cn/" title="世界汉语教学学会">世界汉语教学学会</option>
				<option value="http://www.ccps.com.cn/" title="中华文化促进会">中华文化促进会</option>
				<option value="http://www.yanhuangwang.org/" title="中华炎黄文化研究会">中华炎黄文化研究会</option>
				<option value="http://iashr.bnu.edu.cn/" title="北京师范大学人文宗教高等研究院">北京师范大学人文宗教高等研究院</option>
				<option value="http://www.confucianism.sdu.edu.cn/" title="山东大学儒学高等研究院">山东大学儒学高等研究院</option>
				<option value="http://www.nishan.org.cn/" title="尼山世界文明论坛">尼山世界文明论坛</option>
				<option value="http://www.china-efe.org" title="爱国网">爱国网</option>
			</select>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import Swiper from '@/components/Swiper.vue'
	import {
		reactive,
		toRefs,
		watch,
		onMounted,
		onUnmounted,
		getCurrentInstance
	} from 'vue'
	import {
		useRouter
	} from "vue-router";

	export default {
		name: "HomeView",
		components: {
			Header,
			Footer,
			Swiper
		},
		setup() {
			const internalInstance = getCurrentInstance();
			const $router = useRouter();
			// reactive 数据双向绑定
			const data = reactive({
				swiperList: [],
				screenWidth: null,
				tabOne: 0,
				tabTwo: 0,
				tabThree: 0,
				tabThour: 0,
				tabFive: 0,
				tabOneList_1: [],
				tabOneList_2: [],
				tabOneList_3: [],
				tabTwoList_1: [],
				tabTwoList_2: [],
				tabTwoList_3: [],
				tabThreeList_1: [],
				tabThreeList_2: [],
				tabThreeList_3: [],
				tabThourList_1: [],
				tabThourList_2: [],
				tabThourList_3: [],
				tabFiveList_1: [],
				tabFiveList_2: [],
				tabFiveList_3: [],
				searchTxt: '',
				selectVal1: '国家文物局',
				selectVal2: '二级机构',
				selectVal3: '相关单位',
				selectVal4: '8+1联盟',
			})
			// 方法
			const methods = {
				toArticleList(id) {
					$router.push({
						path: '/article',
						query: {
							id: id
						}
					});
				},
				toArticleDetail(id) {
					$router.push({
						path: '/articleDetail',
						query: {
							id: id
						}
					});
				},
				toSearch() {
					$router.push({
						path: '/searchArticle',
						query: {
							txt: data.searchTxt
						}
					});
				},
				changeSelectLink(event, idx) {
					data['selectVal' + idx] = event.target.name;
					window.open(event.target.value, '_blank');
				}
			}
			// 页面栏目数组
			const columnList = [{
					name: '特别关注',
					id: 13,
					listname: 'tabOneList_1'
				},
				{
					name: '新闻资讯',
					id: 14,
					listname: 'tabOneList_2'
				},
				{
					name: '党的建设',
					id: 15,
					listname: 'tabOneList_3'
				},
				{
					name: '活动策划',
					id: 16,
					listname: 'tabTwoList_1'
				},
				{
					name: '地方动态',
					id: 17,
					listname: 'tabTwoList_2'
				},
				{
					name: '数字长城',
					id: 10,
					listname: 'tabTwoList_3'
				},
				{
					name: '长城学会',
					id: 1,
					listname: 'tabThreeList_1'
				},
				{
					name: '长城资讯',
					id: 2,
					listname: 'tabThreeList_2'
				},
				{
					name: '长城文博',
					id: 3,
					listname: 'tabThreeList_3'
				},
				{
					name: '长城人物',
					id: 4,
					listname: 'tabThourList_1'
				},
				{
					name: '长城保护',
					id: 5,
					listname: 'tabThourList_2'
				},
				{
					name: '长城公园',
					id: 6,
					listname: 'tabThourList_3'
				},
				{
					name: '长城论坛',
					id: 7,
					listname: 'tabFiveList_1'
				},
				{
					name: '长城旅游',
					id: 8,
					listname: 'tabFiveList_2'
				},
				{
					name: '长城文苑',
					id: 9,
					listname: 'tabFiveList_3'
				},
			]
			const getArticleList = (id, name) => {
				let params = {
					columnId: id,
					page: 1,
					size: 4
				};
				internalInstance.appContext.config.globalProperties
					.$get("/article/v1/web/user/queryListByColumnId", params)
					.then((res) => {
						if (res.success) {
							data[name] = res.data.list;
						}
					});
			}
			watch(() => data.screenWidth, (newValue, oldValue) => {
				if (newValue < 769 && oldValue >= 769) {
					data.tabOne = 0;
					data.tabTwo = 0;
					data.tabThree = 0;
					data.tabThour = 0;
					data.tabFive = 0;
				}
				if (newValue >= 769 && oldValue < 769) {
					data.tabOne = 1;
					data.tabTwo = 1;
					data.tabThree = 1;
					data.tabThour = 1;
					data.tabFive = 1;
				}
			})
			//页面加载完成
			onMounted(() => {
				data.screenWidth = document.body.clientWidth;
				window.addEventListener('resize', function() {
					data.screenWidth = document.body.clientWidth;
				});
				internalInstance.appContext.config.globalProperties.$get("/scroll/v1/web/user/list", {tag: "web"}).then((
					res) => {
					if (res.success) {
						data.swiperList = res.data.list;
					}
				});
				// 加载页面栏目文章列表
				columnList.forEach(item => {
					getArticleList(item.id, item.listname);
				})
			})
			//销毁
			onUnmounted(() => {})
			return {
				// toRefs转换为响应式数据
				...toRefs(data),
				...methods
			}
		}
	}
</script>
<style scoped lang="scss">
	#wrapper {
		position: relative;
	}

	.search-box {
		box-sizing: border-box;
		width: 100%;
		height: 45px;
		padding: 5px 4%;

		.search-input {
			flex: 1;
			height: 100%;
			padding: 0 5px;
			border: 1px solid #ccc;
		}

		.search-btn {
			width: 50px;
			height: 100%;
			text-align: center;
			line-height: 35px;
			color: #fff;
			background-color: #9D1515;
			cursor: pointer;
		}
	}

	.article_main {
		font-size: 16px;
		color: #333;

		.article_part {
			width: 100%;

			.article_items {
				width: 100%;

				.article_items_tl {
					width: 100%;
					height: 40px;
					padding: 0px 4%;
					background-color: #f5f5f5;
					cursor: pointer;

					.tl {
						font-size: 18px;
						color: #C73737;
					}
				}

				.article_items_its {
					width: 100%;

					.article_cell {
						position: relative;
						width: 100%;
						height: auto;
						padding: 6px 4% 24px 4%;
						line-height: 30px;
						overflow: hidden;
						border-bottom: 1px solid #f5f5f5;
						cursor: pointer;

						.article_cell_date {
							position: absolute;
							right: 4%;
							bottom: 6px;
							font-size: 12px;
							color: #999;
							line-height: 20px;
						}
					}
				}
			}

			.article_tab {
				width: 100%;
				padding: 2%;
				border-bottom: 1px solid #f5f5f5;

				.article_tab_li {
					position: relative;
					font-size: 17px;
					padding: 0 10px;
					cursor: pointer;

					&::after {
						position: absolute;
						top: 4px;
						right: 0;
						content: '';
						width: 1px;
						height: 16px;
						background: #d3d3d3;
					}

					&:last-child {
						&::after {
							display: none;
						}
					}

					&:hover {
						color: #c44021;
					}

					&.active {
						color: #c44021;
						font-weight: bold;
					}
				}
			}
		}
	}

	.link-box {
		padding: 10px 2% 20px;

		.tl {
			position: relative;
			font-size: 17px;
			color: #c44021;
			font-weight: bold;
			padding-right: 10px;
			margin-right: 10px;
			cursor: pointer;

			&::after {
				position: absolute;
				top: -2px;
				right: 0;
				content: '';
				width: 1px;
				height: 28px;
				background: #d3d3d3;
			}
		}

		select {
			width: 168px;
			padding: 5px;
			margin: 5px;
			background-color: #fff;
			border: 1px solid #ccc;

			option {
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	@media screen and (min-width: 769px) {
		.search-box {
			position: absolute;
			top: 15px;
			right: 2%;
			width: 40%;
		}

		.article_main {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;

			.article_part {
				width: 49%;
				margin-bottom: 20px;

				.article_items {
					.article_items_its {
						min-height: 175px;

						.article_cell {
							padding: 6px 4% !important;

							.article_cell_tl {
								width: 82%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap
							}

							.article_cell_date {
								bottom: 10px !important;
							}
						}
					}
				}

				&:first {
					margin-top: 0px;
				}
			}
		}

		.link-box select {
			margin: 5px 10px;
		}
	}
</style>
