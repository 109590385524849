<template>
	<div class="header flex-row jc-sb at-c">
		<router-link to="./" class="logo">
			<img :src="siteinfo.webLogo" class="logo-img" />
		</router-link>
		<div class="nav-collapse" @click.stop="collapse = !collapse">
			<img src="../assets/img/nav_bg.png" />
		</div>
		<transition name="move" mode="out-in">
			<div class="nav-menu-container flex-col" v-show="!collapse" @click.stop="collapse = true">
				<div class="nav-menu flex-col">
					<router-link to="./">首页</router-link>
					<router-link :to="{name:'article', query: {id:item.id}}" v-for="item in menuList" :key="item.id">
						{{item.columnName}}
					</router-link>
				</div>
			</div>
		</transition>
	</div>
	<div class="header-pc flex-col">
		<router-link to="./" class="logo">
			<img :src="siteinfo.webLogo" class="logo-img" />
		</router-link>
		<div class="nav-menu-pc flex-col">
			<div class="nav-menu flex-row jc-sa at-c">
				<router-link to="./">首页</router-link>
				<router-link :to="{name:'article', query: {id:item.id}}" v-for="item in menuList" :key="item.id"
					class="nav-menu-a">{{item.columnName}}</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		reactive,
		toRefs,
		computed,
		onMounted,
		onUnmounted,
		getCurrentInstance
	} from 'vue'
	import {
		useRouter
	} from "vue-router";

	export default {
		name: "HeadPart",
		setup() {
			const internalInstance = getCurrentInstance();
			const $router = useRouter();
			const data = reactive({
				collapse: true,
				menu: [],
				siteinfo: [],
				menuList: computed(() => {
					return data.menu.filter(function(v) {
						return v.isNavigation;
					})
				})
			})
			const methods = {
				toArticleDetail(id) {
					$router.push({
						path: '/articleDetail',
						query: {
							id: id
						}
					})
				},
			};
			const getMenu = () => {
				let params = {
					openPage: false
				};
				internalInstance.appContext.config.globalProperties
					.$get("/column/v1/web/user/list", params)
					.then((res) => {
						if (res.success) {
							data.menu = res.data.list;
							localStorage.setItem("menuData", JSON.stringify(res.data.list));
						}
					});
			};
			const getInfo = () => {
				let params = {
					id: 1
				};
				internalInstance.appContext.config.globalProperties
					.$get("/website/v1/web/user/detail", params)
					.then((res) => {
						if (res.success) {
							data.siteinfo = res.data;
							localStorage.setItem("siteinfo", JSON.stringify(res.data));
						}
					});
			}

			//页面加载完成
			onMounted(() => {
				getMenu();
				getInfo();
				// if (!localStorage.getItem("menuData")) {
				// 	getMenu();
				// } else {
				// 	data.menu = JSON.parse(localStorage.getItem("menuData"));
				// }

				// if (!localStorage.getItem("siteinfo")) {
				// 	getInfo();
				// } else {
				// 	data.siteinfo = JSON.parse(localStorage.getItem("siteinfo"));
				// }
			});
			//销毁
			onUnmounted(() => {})
			return {
				...toRefs(data),
				...methods
			}
		}
	}
</script>

<style scoped lang="scss">
	.header {
		width: 100%;
		height: 50px;
		position: relative;
		padding: 0 4%;
		box-shadow: 0px -40px 20px #eaeaea inset;

		.logo {
			.logo-img {
				display: block;
				width: 154px;
				height: auto;
			}
		}

		.nav-collapse {
			cursor: pointer;
		}

		.nav-menu-container {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			background: transparent;
			z-index: 2;

			.nav-menu {
				width: 100%;
				background: #f5f5f5;
				cursor: pointer;

				a {
					position: relative;
					width: 100%;
					padding: 10px 4%;
					font-size: 16px;
					color: #333;
					border-bottom: 1px solid #dfdfdf;
				}
			}
		}
	}

	.header-pc {
		display: none;
		height: auto;

		.logo {
			margin: 5px 10px;

			.logo-img {
				display: block;
				width: auto;
				height: 60px;
			}
		}

		.nav-menu-pc {
			width: 100%;
			height: 45px;
			background: #9d1515;

			.nav-menu {
				width: 100%;
				overflow-x: auto;
				overflow-y: hidden;

				a {
					display: block;
					min-width: 82px;
					text-align: center;
					line-height: 45px;
					font-size: 16px;
					color: #fff;

					&:hover {
						background-color: #b92121
					}
				}
			}
		}
	}

	@media screen and (min-width: 769px) {
		.header {
			display: none;
		}

		.header-pc {
			display: flex;
		}
	}

	@media screen and (max-width: 1024px) {
		.header-pc .nav-menu-pc .nav-menu {
			justify-content: flex-start;
		}
	}
</style>
