import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/article',
    name: 'article',
    component: () => import(/* webpackChunkName: "article" */ '../views/ArticleView.vue')
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    component: () => import(/* webpackChunkName: "articleDetail" */ '../views/ArticleDetail.vue')
  },
  {
    path: '/searchArticle',
    name: 'searchArticle',
    component: () => import(/* webpackChunkName: "searchArticle" */ '../views/SearchArticle.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
