<template>
	<div class="foot flex-col jc-c at-c">
		<a class="flex-row">版权所有：{{siteinfo.webTitle}}</a>
		<a class="flex-row" target="_blank"
			href="https://beian.miit.gov.cn/#/Integrated/index">ICP备案：{{siteinfo.webIcp}}</a>
		<a class="flex-row at-c" target="_blank"
			href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11040202440050">
			<img src="../assets/img/dibiao.png" />
			{{siteinfo.webPublicNetwork}}
		</a>
	</div>
</template>

<script>
	import {
		reactive,
		toRefs,
		onMounted,
		onUnmounted,
		getCurrentInstance
	} from 'vue'

	export default {
		name: "FootPart",
		setup() {
			const internalInstance = getCurrentInstance();
			const data = reactive({
				siteinfo: {}
			})
			const getInfo = () => {
				let params = {
					id: 1
				};
				internalInstance.appContext.config.globalProperties
					.$get("/website/v1/web/user/detail", params)
					.then((res) => {
						if (res.success) {
							data.siteinfo = res.data;
							localStorage.setItem("siteinfo", JSON.stringify(res.data));
						}
					});
			}
			//页面加载完成
			onMounted(() => {
				if (!localStorage.getItem("siteinfo")) {
					getInfo();
				} else {
					data.siteinfo = JSON.parse(localStorage.getItem("siteinfo"));
				}
			});
			//销毁
			onUnmounted(() => {})
			return {
				...toRefs(data)
			}
		}
	};
</script>

<style scoped lang="scss">
	.foot {
		width: 100%;
		padding: 10px 4%;
		background-color: #c73737;

		a {
			font-size: 12px;
			color: #fff;
			padding: 5px 0;

			img {
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
		}
	}
</style>
