import {
	baseUrl
} from "../config/env";
import axios from 'axios';
axios.defaults.timeout = 10 * 1000; //设置请求时间
axios.defaults.baseURL = baseUrl; //设置默认接口地址
axios.defaults.headers.retry = 3; //超时请求重试次数
axios.defaults.headers.retryDelay = 1000; //超时请求重试时间间隔

// 设置请求拦截器
axios.interceptors.request.use(config => {
	return config
}, err => {
	return Promise.reject(err)
})

// 设置响应拦截器
axios.interceptors.response.use(res => {
	return res
}, err => {
	if (err.response != undefined) {
		// 登录已失效，请重新登录
		return Promise.reject(err);
	}

	var config = err.config;
	// 如果配置不存在或未设置重试选项，则返回错误信息
	if (!config.headers || !config.headers.retry) {
		// 请求超时，请稍候重试
		return Promise.reject(err);
	}

	// 设置变量即跟踪重试次数
	config.__retryCount = config.__retryCount || 0;

	// 检查是否已经超过了总重试次数
	if (config.__retryCount >= config.headers.retry) {
		// 返回错误信息
		// 请求超时，请稍候重试
		return Promise.reject(err);
	}

	// 重试次数加1
	config.__retryCount += 1;

	// 创建延时器等待发送重试请求
	var backoff = new Promise(function(resolve) {
		setTimeout(function() {
			resolve();
		}, config.retryDelay || 1);
	});

	// 返回调用axios来重试请求
	return backoff.then(function() {
		return axios(config);
	});
})

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params: params
			})
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				reject(err)
			})
	})
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.post(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}
