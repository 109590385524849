<template>
	<swiper class="swiper-container" :pagination="{ clickable: true }" :autoplay="{ autoplay: true }">
		<swiper-slide class="swiper-slide" v-for="item in list" :key="item.articleId"
			@click.stop="handleSide(item.articleId)">
			<img :src="item.scrollImg" alt="" />
			<span class="tl">{{item.scrollName}}</span>
		</swiper-slide>
		<!-- 如果需要分页器 -->
		<div class="swiper-pagination"></div>
	</swiper>
</template>

<script>
	import "swiper/swiper-bundle.css";
	import {
		Swiper,
		SwiperSlide
	} from "swiper/vue";
	import {
		useRouter
	} from "vue-router";
	import SwiperCore, {
		Navigation,
		Pagination,
		Scrollbar,
		A11y,
		Autoplay,
	} from "swiper";
	SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

	export default {
		name: "SwiperCom",
		props: {
			list: {
				type: Array
			}
		},
		components: {
			Swiper,
			SwiperSlide,
		},
		setup() {
			const $router = useRouter();
			const handleSide = (id) => {
				if(id != undefined && id != null && id != "" ){
					$router.push({
						path: '/articleDetail',
						query: {
							id: id
						}
					});
				}
			};
			return {
				handleSide,
				modules: [Navigation, Pagination, Scrollbar, A11y],
			};
		},
	};
</script>

<style lang="scss">
	.swiper-container {
		width: 100%;
		height: 250px;

		.swiper-slide {
			img {
				display: block;
				width: 100%;
				height: 100%;
			}

			.tl {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 30px;
				line-height: 30px;
				padding-left: 10px;
				color: #fff;
				background: rgba(0, 0, 0, 0.4);
			}
		}
	}

	.swiper-pagination-fraction,
	.swiper-pagination-custom,
	.swiper-container-horizontal>.swiper-pagination-bullets {
		bottom: 30px;
	}

	.swiper-pagination-bullet {
		width: 16px;
		height: 6px;
		display: inline-block;
		border-radius: 0px;
		background: #fff;
		opacity: 0.6;
	}

	.swiper-pagination-bullet-active {
		background: #b92121;
		opacity: 1;
	}

	@media screen and (min-width: 769px) {
		.swiper-container {
			height: 350px;
			width: 48%;
			padding-top: 20px;
		}
	}
</style>